const React = require('react');
const { string } = require('prop-types');
const classnames = require('classnames');

const ICON_ID = 'card_stripe';
const namespace = 'ui-pdp-icon ui-pdp-icon--card';

const IconCard = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconCard.propTypes = {
  className: string,
};

IconCard.defaultProps = {
  className: null,
};

module.exports = React.memo(IconCard);
module.exports.ICON_ID = ICON_ID;
